<template>
  <b-sidebar
    id="add-new-user-sidebar"
    v-model="showRecSidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'"
    @hidden="resetForm"
    @change="(val) => $emit('update:isAddNewUserSidebarActive', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Новый пользователь
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="First Name"
            rules="required"
          >
            <b-form-group
              label="Имя"
              label-for="first-name"
            >
              <b-form-input
                id="first-name"
                v-model="userData.firstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Last Name"
            rules="required"
          >
            <b-form-group
              label="Фамилия"
              label-for="last-name"
            >
              <b-form-input
                id="last-name"
                v-model="userData.lastName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <!-- <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->
          <!-- User Role -->
          <b-card-body
            v-if="!isAddNewUserSidebarSub"
            class="invoice-padding form-item-section"
          >
            <div
              ref="roleform"
              class="repeater-form"
              :style="{height: trHeight}"
            >
              <b-row
                v-for="(item, roleindex) in role"
                :key="roleindex"
                ref="rolerow"
                class="pb-1"
              >

                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col
                  cols="12"
                >

                  <!-- Form Input Fields OR content inside bordered area  -->
                  <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                  <div class="d-flex border rounded">
                    <b-row class="flex-grow-1 px-2 pt-2 pb-0">
                      <!-- Single Item Form Headers -->
                      <b-col
                        cols="12"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="t('Project')"
                          rules="required"
                        >
                          <b-form-group
                            :state="getValidationState(validationContext)"
                          >
                            <label class="d-inline">{{ t('Project') }}</label>
                            <v-select
                              v-model="item.project"
                              :state="getValidationState(validationContext)"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="projectOptions"
                              label="name"
                              :clearable="false"
                              class="mb-1 item-selector-title"
                              placeholder="Select Project"
                              :selectable="(option) => role.find(p => p.project !== null ? p.project.id === option.id : undefined) === undefined"
                            >
                              <template #list-header>
                                <li
                                  v-if="projectOptions.length === 0"
                                  class="text-center text-primary my-2"
                                >
                                  <b-spinner
                                    class="align-middle mr-1"
                                  />
                                  <strong>Загрузка...</strong>
                                </li>
                              </template>
                              <!-- <template #option="{ name, id }">
                                {{ name }}
                              </template> -->
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="12"
                      >
                        <validation-provider
                          #default="validationContext"
                          :name="t('Role')"
                          rules="required"
                        >
                          <b-form-group
                            :state="getValidationState(validationContext)"
                          >
                            <label class="d-inline">{{ t('Role') }}</label>
                            <v-select
                              v-model="item.role"
                              :state="getValidationState(validationContext)"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="roleOptions"
                              label="name"
                              :clearable="false"
                              class="mb-1"
                              placeholder="Select Role"
                            >
                              <template #list-header>
                                <li
                                  v-if="roleOptions.length === 0"
                                  class="text-center text-primary my-2"
                                >
                                  <b-spinner
                                    class="align-middle mr-1"
                                  />
                                  <strong>Загрузка...</strong>
                                </li>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(roleindex)"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="addNewItemInItemForm"
            >
              Add Role
            </b-button>
            <b-alert
              v-show="msgShow"
              variant="danger"
              show
              class="mt-2"
            >
              <div class="alert-body font-small-2">
                {{ t('No more projects') }}
              </div>
            </b-alert>
          </b-card-body>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol, BCardBody, BSpinner, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCurProject } from '@/auth/utils'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BCardBody,
    BSpinner,
    BAlert,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:isAddNewUserSidebarActive',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      // required: true,
    },
    isAddNewUserSidebarSub: {
      type: Boolean,
    },
  },
  emits: ['update:isAddNewUserSidebarActive'],
  data() {
    return {
      required,
      alphaNum,
      email,
      msgShow: false,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      if (this.role.length < this.projectOptions.length) {
        this.$refs.roleform.style.overflow = 'hidden'
        this.role.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rolerow[0].offsetHeight)
          setTimeout(() => {
            this.$refs.roleform.style.overflow = null
          }, 350)
        })
      } else {
        this.msgShow = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.msgShow = false
          }, 1000)
        })
      }
    },
    changeHeightInItemForm() {
      this.$refs.roleform.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rolerow[0].offsetHeight)
        setTimeout(() => {
          this.$refs.roleform.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      if (this.role.length) {
        this.role.splice(index, 1)
        this.trTrimHeight(this.$refs.rolerow[0].offsetHeight)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.roleform.scrollHeight)
      // })
    },
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const toast = useToast()

    const itemFormBlankItem = {
      project: null,
      role: null,
    }
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      active: 1,
      // createdAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
      // updatedAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
    }
    const role = ref([JSON.parse(JSON.stringify(itemFormBlankItem))])
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      role.value = ref([JSON.parse(JSON.stringify(itemFormBlankItem))])
    }

    const roleOptions = ref([])
    const projectOptions = ref([])

    store
      .dispatch('app-user/fetchRoles', {
        _fields: 'id,name',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Groups',
      })
      .then(response => {
        const { records } = response.data
        roleOptions.value = records
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-user/fetchProjects', {
        _fields: 'id,name',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Projects',
      })
      .then(response => {
        const { records } = response.data
        projectOptions.value = records
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const pushRole = (uid, roles) => {
      console.log(roles)
      const rows = roles.map(item => ({
        user_id: uid,
        group_id: item.role.id,
        project_id: item.project.id,
        // createdAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
        // updatedAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
      }))

      store.dispatch('app-user/pushRole', rows).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Успешно',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Вы успешно нажали на кнопку сохранить.',
          },
        })
      })
    }

    const showRecSidebar = ref(false)
    const closeSidebar = () => {
      showRecSidebar.value = false
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(async res => {
          if (res.data.error !== undefined) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding a user',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            emit('refetch-data', res.data)
            emit('update:isAddNewUserSidebarActive', false)
            const currentProject = getCurProject()
            console.log(currentProject)
            if (props.isAddNewUserSidebarSub) {
              pushRole(res.data.id, [{
                project: { id: currentProject },
                role: { id: 21 },
              }])
            } else {
              pushRole(res.data.id, role.value)
            }
            closeSidebar()
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      t,
      userData,
      role,
      onSubmit,
      itemFormBlankItem,
      refFormObserver,
      getValidationState,
      resetForm,
      roleOptions,
      projectOptions,
      showRecSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

</style>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';

  .form-item-section {
  background-color:$product-details-bg;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: .35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }

    }
  }
  </style>
